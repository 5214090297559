import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    dashboardPanelExpand: false,
    dashboardPanelActiveTab: null,
    manageTitlesPanelExpand: false,
    subscription: {},
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        clearCommon: (_, action) => initialState,
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setDashboardPanelExpand: (state, action) => {
            state.dashboardPanelExpand = action.payload
        },
        setDashboardPanelActiveTab: (state, action) => {
            state.dashboardPanelActiveTab = action.payload
        },
        setManageTitlesPanelExpand: (state, action) => {
            state.manageTitlesPanelExpand = action.payload
        },
        setSubscription: (state, action) => {
            state.subscription = action.payload
        },
    },
})

export const {
    setCurrentRouteKey,
    setDashboardPanelExpand,
    setDashboardPanelActiveTab,
    setManageTitlesPanelExpand,
    setSubscription,
    clearCommon,
} = commonSlice.actions

export default commonSlice.reducer
