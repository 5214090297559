import { combineReducers } from '@reduxjs/toolkit'
import titles from './titlesSlice'
import selectPlan from './selectPlanSlice'

const reducer = combineReducers({
    titles,
    selectPlan,
})

export default reducer
