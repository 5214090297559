const environment = process.env.NODE_ENV
const appConfig = {
    apiPrefix:
        environment !== 'production'
            ? 'http://localhost:8071'
            : 'https://api.disco.info',
    //apiPrefix: '/api',
    authenticatedEntryPath: '/',
    unAuthenticatedEntryPath: '/',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
