import appConfig from 'configs/app.config'
import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },

    async fetchCSRFToken() {
        // Function to fetch CSRF token
        await BaseService.get(
            `${appConfig.apiPrefix}/sanctum/csrf-cookie`
        )
    },
}

export default ApiService
