import React, { memo, useMemo, lazy, Suspense } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_SEARCH,
    LAYOUT_TYPE_USER_DASH,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useLocation } from 'react-router-dom'
import useAuthority from 'utils/hooks/useAuthority'
import { ADMIN } from 'constants/roles.constant'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    // [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_SEARCH]: lazy(() => import('./SearchLayout')),
    [LAYOUT_TYPE_USER_DASH]: lazy(() => import('./UserDashLayout')),
    // [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const { authenticated } = useAuth()
    const userAuthority = useSelector((state) => state.auth.user.authority)
    const isAdmin = useAuthority(userAuthority, [ADMIN])

    useDirection()

    useLocale()

    const location = useLocation()

    const { pathname } = location

    const AppLayout = useMemo(() => {
        if (authenticated) {
            if (isAdmin) {
                return layouts[LAYOUT_TYPE_MODERN]
            }

            if (
                pathname === '/sign-in' ||
                pathname === '/sign-up' ||
                pathname === '/forgot-password'
            ) {
                return lazy(() => import('./AuthLayout'))
            }

            if (pathname === '/') {
                return layouts[LAYOUT_TYPE_SEARCH]
            } else if (pathname.includes('/dashboard')) {
                return layouts[LAYOUT_TYPE_USER_DASH]
            } else {
                return layouts[LAYOUT_TYPE_SIMPLE]
            }
        }
        return lazy(() => import('./AuthLayout'))
    }, [pathname, authenticated, isAdmin])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
