import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        signedIn: true,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
        },
        onSignOutSuccess: (state) => {
            state.signedIn = true
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken } =
    sessionSlice.actions

export default sessionSlice.reducer
