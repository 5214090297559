import { createSlice } from '@reduxjs/toolkit'
import { ANONYMOUS } from 'constants/roles.constant'

export const initialState = {
    avatar: '',
    userName: 'Anonymous',
    email: 'demo@demo.com',
    authority: [ANONYMOUS],
    api_key: '',
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
