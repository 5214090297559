import axios from 'axios'
import appConfig from 'configs/app.config'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { initialState, setUser } from 'store/auth/userSlice'
import { clearCheckout } from 'store/checkout/titlesSlice'
import { clearFiltersList } from 'store/search/filtersSlice'
import { clearCommon } from 'store/base/commonSlice'
import { resetDashboardView } from 'store/locale/localeSlice'

const unauthorizedCode = [401, 419]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true,
})

BaseService.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error
        if (response && unauthorizedCode.includes(response.status)) {
            const currentPath =
                window.location.pathname + window.location.search
            // Check if the URL already contains the ?_with and redirectUrl parameters
            const urlParams = new URLSearchParams(window.location.search)
            const hasWithParam = urlParams.has('_with')
            const hasRedirectUrl = urlParams.has('redirectUrl')

            // clear user session if response not authorized
            store.dispatch(onSignOutSuccess())
            store.dispatch(setUser(initialState))
            store.dispatch(clearCheckout())
            store.dispatch(clearFiltersList())
            store.dispatch(clearCommon())
            store.dispatch(resetDashboardView())

            if (!hasWithParam || !hasRedirectUrl) {
                window.location.href = `/?_with=sign_in&redirectUrl=${encodeURIComponent(
                    currentPath
                )}`
            }
        }

        return Promise.reject(error)
    }
)

export default BaseService
