import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactSelect, { components as reactSelectComponents } from 'react-select'
import tw, { theme } from 'twin.macro'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import {
    HiCheck,
    HiChevronDown,
    HiChevronUp,
    HiMinus,
    HiX,
} from 'react-icons/hi'
import Spinner from '../Spinner'
import { CONTROL_SIZES, SIZES } from '../utils/constant'
import { transform } from 'lodash'
import { FaDotCircle } from 'react-icons/fa'
import { BsDot } from 'react-icons/bs'

// Custom TreeOption component with improved UI/UX
const TreeOption = ({
    innerProps,
    label,
    selectProps,
    isSelected,
    isDisabled,
    isFocused,
    data,
    setValue,
    getValue,
}) => {
    const { themeColor } = selectProps
    const [isExpanded, setIsExpanded] = useState(true)

    const hasChildren = data.nodes && data.nodes.length > 0
    const selectedValues = getValue()

    useEffect(() => {
        if (
            hasChildren &&
            selectedValues.some((val) =>
                data.nodes.some((child) => child.value === val.value)
            )
        ) {
            setIsExpanded(true)
        }
    }, [selectedValues, data.nodes, hasChildren])

    const toggleExpand = (e) => {
        e.stopPropagation()
        setIsExpanded(!isExpanded)
    }

    const handleSelect = (e) => {
        e.stopPropagation()
        let newValue
        if (isSelected) {
            newValue = selectedValues.filter((val) => val.value !== data.value)
        } else {
            if (hasChildren) {
                newValue = [
                    ...selectedValues.filter(
                        (val) =>
                            !data.nodes.some(
                                (child) => child.value === val.value
                            )
                    ),
                    data,
                ]
            } else {
                newValue = [...selectedValues, data]
            }
        }
        setValue(newValue)
    }

    return (
        <>
            <div
                className={classNames(
                    'select-option',
                    isSelected && 'selected',
                    isDisabled && 'disabled',
                    isFocused && 'focused',
                    'flex justify-between items-center px-3 py-2 rounded-md cursor-pointer hover:bg-blue-100 transition'
                )}
                {...innerProps}
                onClick={handleSelect}
                aria-expanded={isExpanded}
                aria-selected={isSelected}
                role="treeitem"
                style={{
                    paddingLeft: hasChildren ? '20px' : '10px',
                    backgroundColor: isFocused ? '#F0F4F8' : 'transparent',
                    borderLeft: `2px solid ${
                        isSelected ? themeColor : 'transparent'
                    }`,
                }}
            >
                <div className="flex items-center space-x-2">
                    <span
                        onClick={toggleExpand}
                        className="cursor-pointer text-gray-500 hover:text-gray-700"
                        role="button"
                        aria-label={isExpanded ? 'Collapse' : 'Expand'}
                    >
                        {hasChildren ? (
                            isExpanded ? (
                                <HiChevronUp />
                            ) : (
                                <HiChevronDown />
                            )
                        ) : (
                            <BsDot />
                        )}
                    </span>

                    <span>{label}</span>
                </div>
                {isSelected && (
                    <HiCheck
                        className={`text-${themeColor} dark:text-white text-xl`}
                    />
                )}
            </div>
            {isExpanded && hasChildren && (
                <div className="pl-6 border-l-2 border-gray-200">
                    {data.nodes.map((child) => (
                        <TreeOption
                            key={child.value}
                            label={child.label}
                            data={child}
                            selectProps={selectProps}
                            isSelected={selectedValues.some(
                                (val) => val.value === child.value
                            )}
                            isDisabled={isDisabled}
                            isFocused={isFocused}
                            innerProps={innerProps}
                            setValue={setValue}
                            getValue={getValue}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

// Custom DropdownIndicator with better UX
const CustomDropdownIndicator = (props) => {
    return (
        <reactSelectComponents.DropdownIndicator {...props}>
            <HiChevronDown className="text-gray-500 hover:text-gray-700 transition" />
        </reactSelectComponents.DropdownIndicator>
    )
}

// Custom ClearIndicator with better UX
const CustomClearIndicator = (props) => {
    return (
        <reactSelectComponents.ClearIndicator {...props}>
            <HiX className="text-gray-500 hover:text-gray-700 transition" />
        </reactSelectComponents.ClearIndicator>
    )
}

// Custom LoadingIndicator with better UX
const CustomLoadingIndicator = ({ selectProps }) => {
    const { themeColor } = selectProps
    return <Spinner className={`select-loading-indicator text-${themeColor}`} />
}

const customFilterOption = (option, searchInput) => {
    const searchTerm = searchInput.toLowerCase()

    // Check if the parent label matches the search term
    if (option.label.toLowerCase().includes(searchTerm)) {
        return true
    }

    // If there are child nodes, check if any of them match the search term
    if (option.data.nodes) {
        return option.data.nodes.some((child) =>
            child.label.toLowerCase().includes(searchTerm)
        )
    }

    return false
}

// Main Select component with improved UI/UX
const SelectTree = React.forwardRef((props, ref) => {
    const {
        size,
        style,
        className,
        form,
        field,
        components,
        componentAs: Component,
        ...rest
    } = props

    const { themeColor, controlSize, primaryColorLevel, mode } = useConfig()
    const formControlSize = useForm()?.size
    const inputGroupSize = useInputGroup()?.size

    const selectSize = size || inputGroupSize || formControlSize || controlSize

    const twColor = theme`colors`
    const twHeight = theme`height`

    let isInvalid = false

    if (!isEmpty(form)) {
        const { touched, errors } = form

        const touchedField = get(touched, field.name)
        const errorField = get(errors, field.name)

        isInvalid = touchedField && errorField
    }

    const getBoxShadow = (state) => {
        const shadaowBase = '0 0 0 1px '

        if (isInvalid) {
            return shadaowBase + twColor.red['500']
        }

        if (state.isFocused) {
            return shadaowBase + twColor[themeColor][primaryColorLevel]
        }

        return 'none'
    }

    const styles = {
        control: (provided, state) => {
            return {
                ...provided,
                height: twHeight[CONTROL_SIZES[selectSize]],
                minHeight: twHeight[CONTROL_SIZES[selectSize]],
                '&:hover': {
                    boxShadow: getBoxShadow(state),
                    cursor: 'pointer',
                },
                boxShadow: getBoxShadow(state),
                borderRadius: tw`rounded-md`.borderRadius,
                padding: '2px 8px',
                borderColor: isInvalid
                    ? twColor.red['500']
                    : twColor.gray['300'],
                '&:focus-within': {
                    borderColor: twColor[themeColor][primaryColorLevel],
                },
            }
        },
        input: (css) => {
            return {
                ...css,
                input: {
                    outline: 'none',
                    outlineOffset: 0,
                    boxShadow: 'none !important',
                },
            }
        },
        menu: (provided) => ({
            ...provided,
            zIndex: 50,
            borderRadius: tw`rounded-md`.borderRadius,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: twColor[themeColor][50],
            textTransform: 'capitalize',
            borderRadius: tw`rounded-md`.borderRadius,
            padding: '2px 4px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: twColor[themeColor][700],
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: twColor[themeColor][500],
            '&:hover': {
                backgroundColor: twColor[themeColor][200],
                color: twColor[themeColor][700],
            },
        }),
        ...style,
    }

    const selectClass = classNames('select', `select-${selectSize}`, className)

    return (
        <Component
            className={selectClass}
            classNamePrefix={'select'}
            ref={ref}
            styles={styles}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral20:
                        mode === 'dark'
                            ? twColor.gray['600']
                            : twColor.gray['300'],
                    neutral30:
                        mode === 'dark'
                            ? twColor.gray['600']
                            : twColor.gray['300'],
                    neutral80: twColor.gray['700'],
                    neutral10:
                        mode === 'dark'
                            ? twColor.gray['600']
                            : twColor.gray['300'],
                    primary25: twColor[themeColor]['50'],
                    primary50: twColor[themeColor]['100'],
                    primary: twColor[themeColor][primaryColorLevel],
                },
            })}
            themeColor={`${themeColor}-${primaryColorLevel}`}
            isMulti
            components={{
                IndicatorSeparator: () => null,
                Option: (props) => (
                    <TreeOption
                        {...props}
                        setValue={props.selectProps.onChange}
                        getValue={props.getValue}
                    />
                ), // Pass setValue and getValue to TreeOption
                LoadingIndicator: CustomLoadingIndicator,
                DropdownIndicator: CustomDropdownIndicator,
                ClearIndicator: CustomClearIndicator,
                ...components,
            }}
            filterOption={customFilterOption}
            {...field}
            {...rest}
        />
    )
})

SelectTree.propTypes = {
    size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM]),
    componentAs: PropTypes.elementType,
}

SelectTree.defaultProps = {
    componentAs: ReactSelect,
}

export default SelectTree
