import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiGetUser,
    apiSignIn,
    apiSignOut,
    apiSignUp,
    apiVerifyOtp,
} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { clearCheckout } from 'store/checkout/titlesSlice'
import { clearFiltersList } from 'store/search/filtersSlice'
import { clearCommon } from 'store/base/commonSlice'
import { resetDashboardView } from 'store/locale/localeSlice'
import {
    setAuthPanelLoginExpand,
    setAuthPanelResetPasswordExpand,
    setAuthPanelSignupExpand,
} from 'store/theme/themeSlice'
import { setPlanPanelExpand } from 'store/checkout/selectPlanSlice'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { signedIn } = useSelector((state) => state.auth.session)
    const { selectedTitles } = useSelector((state) => state.checkout.titles)

    const signIn = async (values) => {
        try {
            await apiSignIn(values)
            const {
                data: { user },
            } = await apiGetUser()

            if (user) {
                dispatch(onSignInSuccess())
                dispatch(
                    setUser(
                        user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        }
                    )
                )
                dispatch(setAuthPanelLoginExpand(false))
                if (selectedTitles.length > 0) {
                    await dispatch(setPlanPanelExpand(true))
                }
            }
            const redirectUrl = user.redirectUrl || query.get(REDIRECT_URL_KEY)
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )
            return {
                status: 'success',
                message: '',
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.errors,
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.errors,
            }
        }
    }

    const verifyOtp = async (values) => {
        try {
            await apiVerifyOtp(values)
            const {
                data: { user },
            } = await apiGetUser()

            if (user) {
                dispatch(onSignInSuccess())
                dispatch(
                    setUser(
                        user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        }
                    )
                )
                dispatch(setAuthPanelSignupExpand(false))
                dispatch(setAuthPanelResetPasswordExpand(false))
                if (selectedTitles.length > 0) {
                    await dispatch(setPlanPanelExpand(true))
                }
            }
            const redirectUrl = user.redirectUrl || query.get(REDIRECT_URL_KEY)
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )
            return {
                status: 'success',
                message: '',
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.errors,
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        dispatch(clearCheckout())
        dispatch(clearFiltersList())
        dispatch(clearCommon())
        dispatch(resetDashboardView())
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: signedIn,
        signIn,
        signUp,
        signOut,
        verifyOtp,
    }
}

export default useAuth
